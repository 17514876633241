import React from 'react';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from './../components/SEO';
import ProjectCard from './../components/ProjectCard'

import { graphql, Link } from 'gatsby';

const HomePage = ({ data, location }) => {
  const { edges: posts } = data.allMdx;
  const { edges: projects } = data.projects;
  return (
    <Layout>
      <SEO location={location.pathname} title="💻 Home page" />
      <div className="mx-auto max-w-4xl block md:flex md:items-center md:justify-around">
        <div className="h-40 w-40 rounded-full mt-6 mx-auto md:mx-2 overflow-hidden">
          <Img
            fluid={data.fileName.childImageSharp.fluid}
            alt="profile-pic"
          />
        </div>
        <div className="my-6 ml-2 md:w-2/3">
          <h1 className="text-3xl my-3 ml-2 md:text-4xl">Hi! I'm Mohamed 👋</h1>
          <p className="px-2">
            I'm a Software engineer working mainly with{' '}
            <b>Microsoft ecosystem (.Net Core, Azure, ...)</b>.
          </p>
          <p className="px-2">
            On my spare time, I play with <b>Javascript</b> to build
            <Link to="projects/" className="underline px-1">
              tiny apps & tools
            </Link>
            (
            <Link
              to="blog/how-i-made-my-blog-with-gatsby-and-tailwindcss/"
              className="underline px-1"
            >
              including this website
            </Link>
            )
          </p>
          <p className="p-2">
            This blog will be home to different topics (mostly development
            related) that come up while working or learning new stuff, along
            with a few tutorials and other things.
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center flex-col mt-16 max-w-3xl mx-auto">
        <div className="flex items-center justify-between w-full">
          <div className="flex relative items-center font-bold text-2xl">
            <svg className="absolute z-1 -m-5" width="60" height="60" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
              <path d="M381.5 165.9c16.2 17.8 16 53.7 8.1 80.3-7.9 26.6-23.5 43.8-8.9 78 14.7 34.1 59.6 85.2 56.2 101.2-3.3 16.1-54.9-2.8-93.4-3.5-38.5-.7-64 16.9-82.9 11.6-18.9-5.2-31.3-33.3-47.6-56-16.3-22.6-36.4-39.7-55.1-66.6-18.8-26.9-36.1-63.6-36.1-104.2.1-40.6 17.5-85.1 50.1-95.4 32.6-10.3 80.3 13.7 120.5 25.5s72.8 11.4 89.1 29.1z" fill="#A4E5F1" />
            </svg>
            <span className="text-3xl text-blue-600 z-10">#</span><h2 className="z-10">Featured Articles</h2>
          </div>
          <Link
            to="/blog"
            className="block items-center p-2 hover:bg-blue-500 bg-blue-600 text-white font-bold rounded-l-xl rounded-tr-xl">
            View More articles
          </Link>
        </div>
        {posts.map(({ node }, index) => {
          const isEven = index % 2 === 0;
          const flexDirection = isEven ? 'flex-row-reverse' : 'flex-row';
          const imageRounded = isEven ? 'rounded-r-xl' : 'rounded-l-xl';

          return (
            <Link to={node.fields.slug} className="my-2" key={index}>
              <div key={node.id} className={`flex ${flexDirection} justify-center items-center my-3 max-w-3xl rounded-xl shadow-md bg-white`}>
                <div className="h-52 w-full hidden sm:block">
                  <Img
                    className={`w-full h-full object-fit object-top ${imageRounded} overflow-hidden`}
                    fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
                    alt={`${node.title}-thumbnail`}
                  />
                </div>
                <div className="mx-3 my-1 p-1">
                  <div className="text-gray-500 text-xs">
                    {node.frontmatter.date}
                  </div>
                  <h3 className="text-2xl font-bold">{node.frontmatter.title}</h3>
                  <span className="text-gray-600">{node.excerpt}</span>
                </div>
              </div>
            </Link>
          )
        }
        )}
      </div>
      <div className="flex justify-center items-center flex-col mt-16 max-w-3xl mx-auto">
        <div className="flex items-center justify-between w-full">
          <div className="flex relative items-center font-bold text-2xl">
            <svg className="absolute z-1 -m-5" width="60" height="60" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
              <path d="M381.5 165.9c16.2 17.8 16 53.7 8.1 80.3-7.9 26.6-23.5 43.8-8.9 78 14.7 34.1 59.6 85.2 56.2 101.2-3.3 16.1-54.9-2.8-93.4-3.5-38.5-.7-64 16.9-82.9 11.6-18.9-5.2-31.3-33.3-47.6-56-16.3-22.6-36.4-39.7-55.1-66.6-18.8-26.9-36.1-63.6-36.1-104.2.1-40.6 17.5-85.1 50.1-95.4 32.6-10.3 80.3 13.7 120.5 25.5s72.8 11.4 89.1 29.1z" fill="#A4E5F1" />
            </svg>
            <span className="text-3xl text-blue-600 z-10">#</span><h2 className="z-10">Featured Projects</h2>
          </div>
          <Link
            to="/projects"
            className="block items-center p-2 hover:bg-blue-500 bg-blue-600 text-white font-bold rounded-l-xl rounded-tr-xl">
            View More Projects
          </Link>
        </div>
        <div className="max-w-5xl">
          {projects.map(({ node }) => {
            return (
              <ProjectCard
                key={node.id}
                title={node.frontmatter.title}
                body={node.body}
                date={node.frontmatter.date}
                link={node.frontmatter.link}
                thumbnail={node.frontmatter.thumbnail?.childImageSharp?.fluid}
                emoji={node.frontmatter.emoji}
                status={node.frontmatter.status}
              />
            )
          })
          }
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "images/profile.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMdx(filter: {frontmatter: {isFeatured: {eq: true}, type: {eq: "post"}}}) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 630) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    projects: allMdx(filter: {frontmatter: {type: {eq: "project"}, isFeatured: {eq: true}}},
    sort: {order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          id
          excerpt
          body
          frontmatter {
            title
            date(formatString: "MMMM YYYY")
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 630) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            emoji
            status
            link
          }
          slug
        }
      }
    }
  }
`;

export default HomePage;

import React from 'react';
import Img from 'gatsby-image';
import FeaturingTag from "./FeaturingTag"
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import MDXComponents from '../components/mdx';

const ProjectCard = ({ title, body, link, thumbnail, date, status, emoji }) => {
  return (
    <div className="my-4 p-1 rounded-xl shadow-md bg-white">
      <div className="flex items-center mt-2">
        <div className="p-1 rounded-lg bg-gray-100 border border-gray-200 ml-1">
          {
            thumbnail ?
              (<div className="overflow-hidden w-24 h-16 rounded-md m-1 shadow-md">
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={title}
                ><Img
                    alt={`${title}-thumbnail`}
                    className="w-full h-full object-cover"
                    fluid={thumbnail}
                  />
                </a>
              </div>) :
              (<div className="m-1 w-24 h-12 flex items-center">
                <span className="mx-auto text-3xl">{emoji}</span>
              </div>)
          }
        </div>
        <div className="mx-3">
          <span className="text-gray-800 text-sm">
            {date}
          </span>
          <FeaturingTag type={status} />
          {emoji ?
            <h3 className="text-2xl font-bold">{title}</h3>
            :
            <a
              href={link}
              target="_blank"
              rel="noopener"
              aria-label={title}
            >
              <h3 className="text-2xl font-bold">
                {title}
              </h3>
            </a>
          }
        </div>
      </div>
      <div className="mx-2 -m-3">
        <MDXProvider components={MDXComponents}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
    </div>
  )
}

export default ProjectCard;
import React from 'react';

const FeaturingTag = ({ type }) => {
  const commonStyle = "text-white rounded-md px-1 ml-2 text-xs";
  switch (type) {
    case "auto-pilot":
      return (
        <span className={`bg-green-500 ${commonStyle}`}>{type}</span>
      );
    case "open source":
      return (
        <span className={`bg-gray-800 ${commonStyle}`}>{type}</span>
      );
    default:
      return (
        <span className={`bg-blue-500 ${commonStyle}`}>{type}</span>
      );
  }
};

export default FeaturingTag;